import React from 'react'
import { Row, Col, Button, Badge } from 'reactstrap'
import withAppconfig from '../AppContext'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/et'
import 'moment/locale/sv'
import 'moment/locale/fi'
import useWindowDimensions from '../useWindowsDimensions'
import { useTranslation } from 'react-i18next'
// import UpdateIcon from '@material-ui/icons/Loop'
// import UpdateIcon from '@material-ui/icons/Update'
import IconButton from '@material-ui/core/IconButton'
import { useHistory } from 'react-router'
import { ChevronRight } from '@material-ui/icons'
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded'
import ReportIcon from '@material-ui/icons/Report'

const paymentButtonStyle = {
  marginTop: 3,
  marginBottom: 3,
  paddingTop: 1,
  paddingBottom: 1,
}
// const cancelButtonStyle = {
//   marginTop: 3,
//   marginBottom: 3,
//   paddingTop: 1,
//   paddingBottom: 1,
//   backgroundColor: '#ff9216',
//   color: 'white',
// }

function ReservationSymmary({
  r,
  // ispast,
  // isUnpaidList,
  appconfig,
  servertime,
  canceltime,
  accesscode,
  changes,
  // onCancel,
  // onStorage,
  // onDownloadReceipt,
  selected,
  // onSelect,
  // onUnselect,
  onSplitpayment,
  venue,
}) {
  const { t } = useTranslation()
  const { isBsMd } = useWindowDimensions()
  const history = useHistory()

  let courtchanged = false
  let timechanged = false
  if (r.vs.isMoved && changes) {
    // const parent = reservations.find((x) => x.id === r.parentid)
    // if (parent) {
    // let changes = checkCourtAndTimeChange(r, parent)
    courtchanged = changes.courtchanged
    timechanged = changes.timechanged
    // }
  }

  let cancelToState = 'cancelled'
  let _start = moment.utc(r.resourcegroups[0].start)
  let _end = moment.utc(r.resourcegroups[0].end)
  if (r.occurency) {
    let _date = moment.utc(r.occurency.date)
    _start.year(_date.year()).month(_date.month()).date(_date.date())
    _end.year(_date.year()).month(_date.month()).date(_date.date())
  }

  r.vs.isStartAfterCanceltime = _start.isAfter(canceltime)
  r.vs.isStartAfterServertime = _start.isAfter(servertime)
  r.vs.isStartBeforeServertime = _start.isBefore(servertime)
  r.vs.saleRefundPassed = moment
    .utc(_start)
    .add(1, 'day')
    .isBefore(servertime, 'day')
  r.vs.saleWillBeRefunded = moment(_start)
    .subtract(appconfig.cancellimit || 24, 'hours')
    .isBefore(servertime)
  // r.vs.saleStatus = conflict ? conflict.find((x) => x.id === r.id && x.occurency.date === r.occurency.date) : null
  let isSinglePayable = true
  if (r.vs.saleStatus) {
    isSinglePayable =
      r.vs.saleStatus.conflictData && r.vs.saleStatus.conflictData.isconflict
  }
  r.vs.isSinglePayable = isSinglePayable
  if (!r.vs.isRecurring && r.vs.isPayable) {
    if (!r.vs.isSinglePayable) {
      r.vs.isPayable = false
    }
  }

  const {
    isStartAfterCanceltime,
    // isStartAfterServertime,
    isStartBeforeServertime,
    // cost,
    // isCancellable,
    isCancelled,
    isInvoiced,
    isOnSale,
    isPaid,
    isPayable,
    // isReceiptEnabled,
    isRecurring,
    isRefunded,
    isSellable,
    isSplittable,
    isSplitted,
    // isStorable,
    isStored,
    missingPayment,
    payedPayment,
    refundedPayment,
    // showAccessCode,
    saleRefundPassed,
    soldPercentage,
    organisationName,
    hasMatch,
  } = r.vs
  let _accesscode = r.accesscode ? r.accesscode : accesscode ? accesscode : null
  if (appconfig.donotshowaccesscode) {
    _accesscode = null
  }

  const resourceid = r.resourcegroups[0].resources[0].id

  if (
    appconfig.accessnotusedresources &&
    appconfig.accessnotusedresources.includes(resourceid)
  ) {
    _accesscode = null
  }
  // const _payments = r.payments ? r.payments.filter((x) => {
  //   if (!isRecurring) {
  //     return true
  //   } else {
  //     return x.occurency === r.occurency?.date
  //   }
  // }) : []

  // let _isCancellable = false //isCancellable && _irto && _vakio
  // const _isRecCancellable = isCancellable && isStartAfterServertime && isRecurring && !isSellable
  // const _isSinCancellable = isCancellable && isStartAfterServertime && !isRecurring && !isSellable

  // let _showSellable = true
  // if (appconfig.notsellablewhenstorable && isStorable && isSellable) {
  //   _showSellable = false
  // }

  let soldPartially = false
  let soldFully = false
  if (r.vs.saleState) {
    if (r.vs.saleState.p > 0 && r.vs.saleState.p < 1) {
      soldPartially = true
    } else if (r.vs.saleState.p === 1) {
      soldFully = true
    }
  } else if (soldPercentage > 0) {
    // refundpercentage
    if (soldPercentage < 1) {
      soldPartially = true
    } else {
      soldFully = true
    }
  } else if (r.vs.saleStatus && r.vs.saleStatus.conflictData.isconflict) {
    // old conflict check
    soldPartially = true
  }

  let title = '' //t('cancel') // Peru'
  let paymentinfo = ''
  let isFullySold = false
  if (isRecurring) {
    // _isCancellable = _isRecCancellable
    // vakiot
    // cancelled
    if (isCancelled && !isStored) {
      title = ''
      paymentinfo = t('cancelled')
    } else if (isStored) {
      paymentinfo = t('stored')
      if (isPaid) {
        paymentinfo += ', ' + t('paid')
      }
      if (isOnSale) {
        paymentinfo = t('storage') + ', '
        if (soldPartially) {
          paymentinfo += t('onsale') + ', ' + t('sold_partially')
        } else if (soldFully) {
          paymentinfo += t('sold')
        } else {
          paymentinfo += t('onsale_not_sold_this_moment')
        }
      }
      // invoiced
      // onsale
    } else if (isOnSale) {
      title = ''
      paymentinfo = 'Vakiovuoro laitettu myyntiin.'

      if (soldPartially) {
        paymentinfo = t('sold_partially')
      } else if (soldFully) {
        paymentinfo = t('sold')
      }

      // before canceltime
      if (isStartAfterCanceltime && (soldFully || soldPartially)) {
        paymentinfo = paymentinfo + '. Varaaja voi peruuttaa vielä'
      }

      if (!soldFully && !soldPartially) {
        if (isStartBeforeServertime) {
          paymentinfo = t('was_on_sale')
        } else {
          paymentinfo = t('onsale_not_sold_this_moment')
        }
      }

      if (soldFully) {
        isFullySold = true
      }
      // reserved
    } else {
      // istartbeforeservertime
      if (!isStartBeforeServertime) {
        // cannot cancel
        if (missingPayment.missing) {
          // paymentinfo = t('unpaid') + ' ' + missingPayment.amount + ' €.'
          if (isSellable) {
            title = appconfig.recurrentassingle
              ? t('cancel_reservation')
              : t('set_onsale')
            cancelToState = appconfig.recurrentassingle ? 'cancel' : 'onsale'
          }
        } else {
          paymentinfo = t('paid')
          // title = ''
          if (isSellable) {
            // eslint-disable-next-line no-unused-vars
            title = t('set_onsale')
            // eslint-disable-next-line no-unused-vars
            cancelToState = 'onsale'
            // title = appconfig.recurrentassingle ? t('cancel_reservation') : t('set_onsale')
            // cancelToState = appconfig.recurrentassingle ? 'cancel' : 'onsale'
          }
        }
      } else {
        // can set onsale
        // title = t('set_onsale')
        // cancelToState = 'onsale'
        if (isInvoiced) {
          // invoiced
          // paymentinfo = t('invoiced')
          // title = ''
        } else if (isPaid) {
          // paid
          paymentinfo = t('paid')
          // title = ''
        }
      }
    }
  } else {
    // _isCancellable = _isSinCancellable
    // irtovuoro
    // cancelled

    if (isCancelled) {
      paymentinfo = t('cancelled')
      // refunded
      if (isRefunded) {
        if (refundedPayment.isMinuteRefund) {
          paymentinfo = [
            t('cancelled_and_returned.part1'),
            refundedPayment.amount * 100,
            'min',
            t('cancelled_and_returned.part2'),
            moment(refundedPayment.timestamp, 'YYYY.MM.DD HH:mm').format(
              'D.M.YYYY'
            ),
          ].join(' ')
        } else {
          paymentinfo = [
            t('cancelled_and_returned.part1'),
            refundedPayment.amount,
            '€',
            t('cancelled_and_returned.part2'),
            moment(refundedPayment.timestamp, 'YYYY.MM.DD HH:mm').format(
              'D.M.YYYY'
            ),
          ].join(' ')
        }
        // paid
      } else if (payedPayment.payed) {
        paymentinfo = [
          t('cancelled_and_willbereturned.part1'),
          payedPayment.amount,
          '€',
          t('cancelled_and_willbereturned.part2'),
          payedPayment.amount,
        ].join(' ')
      }
    } else if (isOnSale) {
      // onsale
      paymentinfo = t('onsale')
      // paid
      // refunded
      if (isRefunded) {
        paymentinfo = [
          t('sold_and_returned'),
          moment(refundedPayment.timestamp, 'YYYY.MM.DD HH:mm').format(
            'D.M.YYYY'
          ),
          refundedPayment.amount,
          '€',
        ].join(' ')
      } else if (saleRefundPassed) {
        // saleRefundPassed
        paymentinfo = t('was_on_sale')
      } else {
        // isConflict
        // const saleStatus = conflict ? conflict.find((x) => x.id === r.id) : null
        if (
          (r.vs.saleStatus && r.vs.saleStatus.conflictData.isconflict) ||
          (r.vs.saleState && r.vs.saleState.d)
        ) {
          if (r.vs.saleState && r.vs.saleState.d) {
            if (r.vs.saleState.p < 1) {
              paymentinfo = t('sold_partially')
            } else {
              paymentinfo = t('sold')
            }
          } else {
            if (r.vs.saleWillBeRefunded) {
              paymentinfo = t('sold') + '.'
            } else {
              paymentinfo = t('onsale_sold_this_moment')
            }
          }
        } else {
          paymentinfo = t('onsale_not_sold_this_moment')
        }
      }
    } else {
      // reserved
      // title = ''
      // // isStartAfterCanceltime
      // // isStartAfterServertime
      // if (isStartAfterCanceltime) {
      //   title = t('cancel_reservation')
      // } else if (isStartAfterServertime && isSellable) {
      //   title = t('set_onsale')
      //   cancelToState = 'onsale'
      // }
    }
  }
  let _venue = null
  if (venue) {
    if (venue !== 'default') {
      // uppercase first letter
      _venue = venue.charAt(0).toUpperCase() + venue.slice(1)
    }
  }

  const _splits = r.onetimes
    ? r.onetimes.filter((x) => {
        return x.occurency === r.occurency?.date
      })
    : []

  const goReservations = () => {
    history.push(appconfig.pathPrefix + '/reservations')
  }

  const renderPayButton = () => {
    if (isPayable && !isFullySold) {
      return (
        <Button
          size={'sm'}
          color='primary'
          onClick={() => goReservations()}
          key={'-bttn-' + Math.random().toString()}
          style={paymentButtonStyle}
        >
          {t('pay') + ' '}
          <Badge color='success' pill>
            {missingPayment.amount + ' €'}
          </Badge>
        </Button>
      )
    } else {
      return null
    }
  }

  const clicked = (e, from) => {
    e.stopPropagation()
    if (from === 'split' && isSplittable) {
      onSplitpayment(r)
    } else {
      goReservations()
    }
  }

  return (
    <React.Fragment key={r.id + Math.random().toString()}>
      <Row
        className='border mb-2 rounded-sm'
        style={{
          marginRight: 0,
          marginLeft: 0,
          marginTop: 5,
          paddingBottom: 5,
          backgroundColor: selected ? '#d1f3d1' : 'none',
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
          cursor: 'pointer',
        }}
        // key={r.id}
        onClick={(e) => clicked(e, 'bg')}
      >
        <Col>
          <Row>
            <Col xs={10} md={8}>
              <Row>
                <Col
                  style={{
                    textTransform: 'capitalize',
                    paddingLeft: 5,
                    fontSize: 'small',
                  }}
                  className='text-left'
                >
                  {_start.format('dddd D.M') +
                    ' ' +
                    _start.format('H:mm') +
                    ' - ' +
                    _end.format('H:mm')}{' '}
                  {timechanged ? (
                    <Badge color='info'>
                      {t('change_of_time_at_recurreccy')}
                    </Badge>
                  ) : null}
                  {/* {isRecurring ? <UpdateIcon color={'disabled'} /> : <></>} */}
                  {organisationName ? '/ ' + organisationName : null}
                </Col>
              </Row>
              <Row>
                <Col
                  style={{
                    textTransform: 'capitalize',
                    paddingLeft: 5,
                    fontSize: 'small',
                  }}
                  className='text-left'
                >
                  {r.resourcegroups[0].resources[0].displayName}{' '}
                  {_venue ? `- ${_venue}` : null}
                  {courtchanged ? (
                    <Badge color='info'>
                      {t('change_of_court_at_recurreccy')}
                    </Badge>
                  ) : null}{' '}
                </Col>
              </Row>
              {/* <Row>
                <Col style={{ paddingLeft: 5, fontSize: 'small' }} className='text-left align-middle'>{cost ? t('price') + ' ' + cost + ' €' : null}{' '}{!isBsMd ? renderPayButton() : null} </Col>
              </Row> */}
              {_accesscode ? (
                <Row>
                  <Col
                    style={{ paddingLeft: 5, fontSize: 'small' }}
                    className='text-left'
                  >
                    {t('accesscode')}: {_accesscode}
                  </Col>
                </Row>
              ) : null}
              {r.items && r.items.length > 0
                ? r.items.map((x) => (
                    <Row key={x.id}>
                      <Col
                        className='text-left font-italic'
                        style={{ fontSize: 'small' }}
                      >
                        {x.item.displayName} {x.amount} kpl
                      </Col>
                    </Row>
                  ))
                : null}
              {isBsMd && r.usernotes && r.usernotes.length > 0 ? (
                <Row className=''>
                  <Col
                    className='text-left font-italic'
                    style={{ fontSize: 'small' }}
                  >
                    {r.usernotes
                      ? r.usernotes.map((x) => <div>{x.note}</div>)
                      : null}
                  </Col>
                </Row>
              ) : null}
              {hasMatch ? (
                <Row>
                  <Col
                    className='text-left font-italic'
                    style={{ fontSize: 'small' }}
                  >
                    {t(
                      'reservation_contains_match_with_possible_other_participants'
                    )}
                  </Col>
                </Row>
              ) : null}
              {isSplitted && !isPaid ? (
                <Row>
                  <Col
                    className='text-left'
                    style={{ fontSize: 'small', paddingLeft: 5 }}
                  >
                    {_splits && _splits.length > 0
                      ? _splits.map((x) => {
                          // const sum = (Math.round(x.price) / 100).toFixed(2).replace('.', ',') + '€'
                          const title =
                            x.title.length > 20
                              ? x.title.substring(0, 20) + '...'
                              : x.title
                          return (
                            <span className='pl-1'>
                              {x.paid ? (
                                <DoneOutlineRoundedIcon
                                  fontSize='small'
                                  style={{ color: 'green' }}
                                />
                              ) : (
                                <ReportIcon
                                  fontSize='small'
                                  style={{ color: 'red' }}
                                />
                              )}{' '}
                              {title}
                            </span>
                          )
                        })
                      : null}
                  </Col>
                </Row>
              ) : null}
            </Col>
            {isBsMd ? (
              <Col
                xs={11}
                md={3}
                className={
                  !isBsMd ? 'text-left mt-2 border-top pt-1' : 'text-right'
                }
                style={{ paddingLeft: 5 }}
              >
                <Row>
                  <Col
                    className=''
                    style={{ fontSize: 'small', paddingRight: 3 }}
                  >
                    {paymentinfo}
                  </Col>
                </Row>
                <Row>
                  <Col
                    className=''
                    style={{ fontSize: 'small', paddingRight: 3 }}
                  >
                    {renderPayButton()}{' '}
                    {isSplittable && !selected ? (
                      <Button
                        color='info'
                        size={'sm'}
                        style={paymentButtonStyle}
                        onClick={(e) => clicked(e, 'split')}
                      >
                        {t('split_payment')}
                      </Button>
                    ) : null}
                  </Col>
                </Row>
                {/* <Row>
                <Col className='' style={{ fontSize: 'small', paddingRight: 3 }}>
                  {_isCancellable && !selected ? (
                    <Button
                      color='inherit'
                      size={'sm'}
                      style={cancelButtonStyle}
                      onClick={() => onCancel(r.selectkey, cancelToState)}
                    >
                      {title}
                    </Button>
                  ) : null}{' '}
                  {isSellable && !selected && _showSellable ? (
                    <Button
                      color='inherit'
                      size={'sm'}
                      style={cancelButtonStyle}
                      onClick={() => onCancel(r.selectkey, cancelToState)}
                    >
                      {title}
                    </Button>
                  ) : null}{' '}
                  {isStorable && !selected && appconfig.storage && !appconfig.storageonlyviaonsale ? (
                    <Button
                      color='inherit'
                      size={'sm'}
                      style={cancelButtonStyle}
                      onClick={() => onStorage(r.selectkey)}
                    >
                      {t('set_to_storage')}
                    </Button>
                  ) : null}
                </Col>
              </Row> */}
              </Col>
            ) : null}
            <Col
              xs={2}
              md={1}
              className='text-right justify-content-center align-self-center'
            >
              <IconButton
                size='small'
                onClick={() =>
                  history.push(appconfig.pathPrefix + '/reservations')
                }
              >
                <ChevronRight fontSize='inherit' />
              </IconButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withAppconfig(ReservationSymmary)
