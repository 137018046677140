
import React, { useState } from 'react'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const TermsModal = ({ isOpen, toggle, appconfig, defaultLanguage }) => {
  const { t } = useTranslation()  


  const termsAndConditions = () => {
    let lang = defaultLanguage.toLowerCase()
    if (lang === 'se') lang = 'sv'
    if (lang === 'gb') lang = 'en'
    if (appconfig && appconfig.terms) {
      let _terms = null
      if (!appconfig.terms[lang]) {
        _terms = appconfig.terms['fi']
      } else {
        _terms = appconfig.terms[lang]
      }
      if(!_terms) return null
      _terms = _terms.replace(/\\n/g, '\n')
      _terms = _terms.replaceAll('{CANCELLATION_LIMIT}', appconfig.cancellimit || '24')
      return _terms
    } else {
      return null
    }
  }

  const terms = termsAndConditions()

  return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('tandc.reservation_terms')}</ModalHeader>
        <ModalBody>
          {terms ? (
            <div style={{ whiteSpace: 'pre-line' }}>
              {terms}
              <p>{t('tandc.returnpolicy')}</p>
            </div>
          ) : (
            <div>
              <strong>{t('tandc.title1')}</strong>
              <p>{t('tandc.body1')}</p>
              <strong>{t('tandc.title2')}</strong>
              <p>{t('tandc.body2')}</p>
              <strong>{t('tandc.title3')}</strong>
              <p>{t('tandc.body3')}</p>
              <p>{t('tandc.body4').replaceAll('{CANCELLIMIT}', appconfig.cancellimit || '24')}</p>{' '}
              <p>{t('tandc.returnpolicy')}</p>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={toggle}>
            {t('close')}
          </Button>
        </ModalFooter>
      </Modal>
  )
}

export default TermsModal