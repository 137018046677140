import React, { useState } from 'react'
import {
  Container,
  Row,
  Col,
  Alert,
  Button,
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap'
import TopNavigation from '../../TopNavigation'
import withAppconfig from '../../AppContext'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import MatchCard from '../../components/Match/MatchCard'
import { CircularProgress, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useFirebase, isLoaded } from 'react-redux-firebase'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import NextMatches from '../../components/Match/NextMatches'
import ShareDialog from '../../components/ShareDialog'
import Box from '@material-ui/core/Box'

const mapStateToProps = (state, props) => {
  console.log('matches state', props)
  const { firebase } = state
  const { auth } = props
  if (!firebase.ordered.matches) return { matches: null }
  const matches = firebase.ordered.matches
    .map((m) => {
      return { id: m.key, ...m.value }
    })
    .filter((m) => m.state !== 'cancelled')
    .filter((m) => {
      if (m.participants) {
        const isParticipant = m.participants.find((p) => p.id === auth.uid)
        return !isParticipant
      }
      return true
    })

  return {
    matches,
  }
}

const withMatches = compose(
  connect(({ firebase: { auth }, globaltime: { gtime } }) => ({
    auth,
    gtime,
  })),
  // connect((state) => ({ auth: state.firebase.auth })),
  firebaseConnect((props) => {
    return [
      {
        path: 'matches/' + props.appconfig.accountkey + '',
        queryParams: [
          'orderByChild=startAsTimestamp',
          'startAt=' + props.gtime.unix(),
        ],
        storeAs: 'matches',
      },
    ]
  }),
  connect(mapStateToProps)
)

function MatchesScreen({ appconfig, matches, auth, gtime, dispatch }) {
  const params = useParams()
  const { t } = useTranslation()
  const firebase = useFirebase()
  const isAuth = auth.isLoaded && !auth.isEmpty
  let defaultLanguage = 'FI'
  const history = useHistory()
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedMatch, setSelectedMatch] = useState(null)
  const [socialOpen, setSocialOpen] = useState(false)
  const [sharelink, setShareLink] = useState('')

  const matchid = params.matchid
  console.log('params', params)

  const goToJoinMatch = (match) => {
    dispatch({
      type: 'UPDATE_PAYABLE_MATCH',
      payload: match,
    })
    history.push(appconfig.pathPrefix + '/joinmatch')
  }

  const cancelModal = () => {
    setSelectedMatch(null)
    setConfirmationOpen(false)
  }

  const confirmCancel = (match) => {
    setSelectedMatch(match)
    setConfirmationOpen(true)
  }

  const cancelMatchParticipation = async () => {
    setConfirmationOpen(false)
    setLoading(true)
    const matchObject = {
      id: selectedMatch.id,
      customerid: appconfig.accountkey,
    }
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'matches-cancel' : 'matches-cancel'
    let cancel = firebase.functions().httpsCallable(fn)
    try {
      const response = await cancel(matchObject)
      setLoading(false)
      if (response.data.error) {
        alert(response.data.message)
      }
    } catch (error) {
      setLoading(false)
      alert(error.message)
    }
  }

  const closeSocial = () => {
    setSocialOpen(false)
  }

  const shareClick = (match) => {
    const link =
      'https://' +
      (appconfig.istest
        ? 'utest.cintoia.com' + appconfig.pathPrefix
        : appconfig.hosts[0]) +
      '/matches/' +
      match.id
    setShareLink(link)
    setSocialOpen(true)
  }

  if (matchid && matches) {
    if (loading)
      return (
        <div>
          Haetaan ottelun tietoja <CircularProgress />{' '}
        </div>
      )
    const match = matches.find((m) => m.id === matchid)
    if (!match)
      return (
        <div>
          Match not found<pre>{JSON.stringify(matches, null, 2)}</pre>
        </div>
      )
    return (
      <MatchCard
        match={match}
        collection={'matches'}
        isAuthRequired={true}
        isAuth={isAuth}
        lng={defaultLanguage}
        paymentAction={goToJoinMatch}
        cancelAction={confirmCancel}
        uid={auth.uid}
        shareClick={shareClick}
        gtime={gtime}
      />
    )
  }

  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' style={{ paddingTop: 10 }}>
        {!isAuth ? (
          <Alert color='warning'>{t('login_to_join_matches')}</Alert>
        ) : null}
        <Typography variant='h6' style={{ paddingBottom: 20 }}>
          {t('matches')}
        </Typography>
        <Row>
          <Col>
            <NextMatches />
          </Col>
        </Row>
        
        {matches && matches.length > 0 ? (
          <React.Fragment>
            <Typography variant='h6' style={{ marginTop: 20, paddingBottom: 20 }}>
              {t('publicly_available_matches')}
            </Typography>
          <Row>
            {matches.map((m) => (
              <Col key={m.id} xs={12} sm={4}>
                <MatchCard
                  key={m.id}
                  match={m}
                  collection={'matches'}
                  isAuthRequired={true}
                  isAuth={isAuth}
                  lng={defaultLanguage}
                  paymentAction={goToJoinMatch}
                  cancelAction={confirmCancel}
                  uid={auth.uid}
                  shareClick={shareClick}
                  gtime={gtime}
                />
              </Col>
            ))}
          </Row>
          </React.Fragment>
        ) : (
          <Row className='mt-5'>
            <Col className='text-center'>
              <Typography variant='body1'>
                {t('no_matches_to_participate')}
              </Typography>
              <Box p={5}>
                <Typography variant='body2'>
                  {t('no_matches_to_participate_info')}
                </Typography>
              </Box>
            </Col>
          </Row>
        )}{' '}
      </Container>

      <Modal
        size='sm'
        centered
        isOpen={loading}
        backdrop={true}
        wrapClassName={'al-wrap'}
        modalClassName={'al-modal'}
        contentClassName={'loading-spinner'}
      >
        <ModalBody className='text-center'>
          <Spinner color='light' />{' '}
          <p style={{ color: 'white' }}>{t('cancelling')}</p>
        </ModalBody>
      </Modal>

      <Dialog
        open={confirmationOpen}
        onClose={() => cancelModal()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Haluatko varmasti perua osallistumisen?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancelModal()} color='secondary'>
            {t('back')}
          </Button>
          <Button
            onClick={() => cancelMatchParticipation()}
            color='danger'
            autoFocus
          >
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <ShareDialog open={socialOpen} onClose={closeSocial} link={sharelink} />
    </div>
  )
}

export default withAppconfig(withMatches(MatchesScreen))
