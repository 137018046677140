import React, { useEffect, useState, useCallback } from 'react'
import withAppconfig from '../../AppContext'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { Container, Row, Col, Alert } from 'reactstrap'
import TopNavigation from '../../TopNavigation'
import MatchCard from '../../components/Match/MatchCard'
import { useTranslation } from 'react-i18next'



const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile },globaltime: { gtime } }) => ({
    auth,
    profile,
    gtime
  })
)

function InvitationMatchScreen({ appconfig, auth, gtime, dispatch }) {
  const history = useHistory()
  const params = useParams()
  const firebase = useFirebase()
  const isAuth = auth.isLoaded && !auth.isEmpty
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [match, setMatch] = useState(null)
  const { t } = useTranslation()
  let defaultLanguage = 'FI'  

  const matchid = params.matchid
  firebase.functions().region = 'europe-west1'
  const fn = appconfig.istest ? 'matches-get' : 'matches-get'

  const fetchData = useCallback(
    (id) => {
      if (id) {
        const get = firebase.functions().httpsCallable(fn)
        get({ id, customerid: appconfig.accountkey })
          .then((response) => {
            setMatch(response.data.match)
            setLoading(false)
            if (response.data.error) {
              alert(response.data.message)
            }
          })
          .catch((error) => {
            setError(error)
            setLoading(false)
            alert(error.message)
          })
      }
    },
    [firebase, fn, appconfig.accountkey]
  )

  useEffect(() => {
    fetchData(matchid)
  }, [fetchData, matchid])

  const goToJoinMatch = (match) => {
    dispatch({
      type: 'UPDATE_PAYABLE_MATCH',
      payload: match,
    })
    history.push(appconfig.pathPrefix + '/joinmatch')
  }

  return (
    <div className='full-page-background'>
      <TopNavigation />
      <Container className='front' style={{ paddingTop: 10 }}>
        {!isAuth ? (
          <Alert color='warning'>{t('login_to_join_matches')}</Alert>
        ) : null}
        {loading ? (
          <Row>
            <Col>
              <CircularProgress />
            </Col>
          </Row>
        ) : !error && (
          <Row>
            <Col key={match.id} xs={12} sm={4}>
              <MatchCard
                key={match.id}
                match={match}
                collection={'matches'}
                isAuthRequired={true}
                isAuth={isAuth}
                lng={defaultLanguage}
                paymentAction={goToJoinMatch}
                uid={auth.uid}
                // shareClick={shareClick}
                gtime={gtime}
              />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default withAppconfig(enhance(InvitationMatchScreen))
