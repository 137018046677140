import React, { useState, useEffect } from 'react'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/et'
import 'moment/locale/sv'
import 'moment/locale/fi'
import { useHistory } from 'react-router-dom'
import withAppconfig from '../AppContext'
import { connect } from 'react-redux'
import { getTimeStops, getResources, getResourceTimes, formatSlot, getResourceRow } from './ViewUtils'
import useWindowDimensions from '../useWindowsDimensions'
import DurationPopover from './DurationPopover'
import { t } from 'i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// const APPINITLANG = 'fi'

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile
  })
)

const styles = {
  td: {
    fontSize: 'x-small',
    // borderTop: '1px solid black',
    // borderLeft: '1px solid black',
    // borderRight: '1px solid black',
    border: '1px solid white',
    // transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    backgroundColor: '#007bff', //'#47c75a',//'lightskyblue',
    color: 'white',
    borderRadius: 4,
    cursor: 'pointer',
  },
  tr: { borderBottom: '1px solid #ebebeb' },
  th: {
    fontSize: 'small',
    // borderTop: '1px solid black',
    // borderLeft: '1px solid black',
    // borderRight: '1px solid black',
    // border: '1px solid black',
    // transform: 'rotate(-90deg)',
  },
  thtime: {
    fontSize: 'small',
    width: 25,
    minWidth: 25,
    // borderTop: '1px solid black',
    // borderLeft: '1px solid black',
    // borderRight: '1px solid black',
    // border: '1px solid black',
    // transform: 'rotate(-90deg)',
  },
  table: {
    borderCollapse: 'collapse',
    // transform: 'rotate(90deg)',
    // transformOrigin: 'top left'
    // borderLeft: '1px solid black',
    // borderRight: '1px solid black',
  },
  verticalresource: {
    fontSize: 'small',
    borderBottom: '1px solid white',
  },
}

const selectorCellStyle = ({ hover, start, end }) => ({
  fontSize: 'x-small',
  borderLeft: start ? '1px solid white' : '0px solid white',
  borderRight: end ? '1px solid white' : '0px solid white',
  // border: space ? '1px solid white' : '0px solid white',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  // textAlign: 'center',
  width: 30,
  background:
    start && end && !hover ? 'repeating-linear-gradient(45deg,#007bff,#007bff 3px,#465298 3px,#465298 6px)' : 'none',
  backgroundColor: hover ? '#47c75a' : '#007bff', //'lightskyblue',
  color: 'white',
  borderTopLeftRadius: start ? '0.3rem' : '0rem',
  borderBottomLeftRadius: start ? '0.3rem' : '0rem',
  borderTopRightRadius: end ? '0.3rem' : '0rem',
  borderBottomRightRadius: end ? '0.3rem' : '0rem',
  cursor: 'pointer',
})

const ownCellStyle = ({ hover, start, end }) => ({
  fontSize: 'x-small',
  borderLeft: start ? '1px solid white' : '0px solid white',
  borderRight: end ? '1px solid white' : '0px solid white',
  // border: space ? '1px solid white' : '0px solid white',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  // textAlign: 'center',
  width: 30,
  background:
    start && end && !hover ? 'repeating-linear-gradient(45deg,#007bff,#007bff 3px,#465298 3px,#465298 6px)' : 'none',
  backgroundColor: hover ? 'lightgreen' : 'green', //'lightskyblue',
  color: 'white',
  borderTopLeftRadius: start ? '0.3rem' : '0rem',
  borderBottomLeftRadius: start ? '0.3rem' : '0rem',
  borderTopRightRadius: end ? '0.3rem' : '0rem',
  borderBottomRightRadius: end ? '0.3rem' : '0rem',
  cursor: 'pointer',
})

const SelectorCell = ({ span, cell, onClick, time, start, end, rowspan }) => {
  const [hover, setHover] = useState(false)

  const select = (cell) => {
    setHover(true)
    onClick(cell)
  }
  return (
    <td
      // key={key}
      onClick={() => select(cell)}
      colSpan={span || 1}
      rowSpan={rowspan || 1}
      style={selectorCellStyle({ hover, start, end })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {time ? time.replace(/^0/, '') : ''}
      <br />
      <span>Varaa</span>
    </td>
  )
}

const OwnCell = ({ span, cell, onClick, time, start, end, rowspan }) => {
  const [hover, setHover] = useState(false)

  const select = (cell) => {
    setHover(true)
    onClick(cell)
  }
  return (
    <td
      // key={key}
      onClick={() => select(cell)}
      colSpan={span || 1}
      rowSpan={rowspan || 1}
      style={ownCellStyle({ hover, start, end })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {time ? time.replace(/^0/, '') : ''}
      <br />
      <span>Oma</span>
    </td>
  )
}

// const countSpan = (c0, c1) => {
//   if (!c1) return c0.times[0].duration / 30
//   const diff = moment.utc(c1.start).diff(moment.utc(c0.start), 'minutes', true)
//   if (diff === 30) {
//     return 1
//   } else {
//     return c0.times[0].duration / 30
//   }
// }

function HorizontalView({ dailyFreeTimes, selectedvenue, appconfig, servertime, weather, dispatch, ownreservations }) {
  const history = useHistory()
  const { width } = useWindowDimensions()
  const [durationDialogOpen, setDurationDialogOpen] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState(null)
  const [times, setTimes] = useState([])
  const [trs, setTrs] = useState([])
  const [scrollPosition, setScrollPosition] = useState({ position: 'absolute', left: 0 });  
  const isSm = useMediaQuery('(max-width:600px)')

  const toggle = () => setDurationDialogOpen(!durationDialogOpen)

  const handleClick = (slot) => {
    console.log('slot', slot)
    if (slot.times && slot.times.length > 1) {
      setSelectedSlot(slot)
      setDurationDialogOpen(true)
    } else if (slot.times && slot.times.length === 1) {
      const d = slot.times[0].duration
      const _slot = formatSlot(slot)
      _slot.view = 'horizontal'
      selectReservation(_slot, d)
    }
  }

  const handleScroll = (e) => {
    const { scrollLeft } = e.target;
    // console.log('scrollLeft', scrollLeft)
    setScrollPosition({ position: 'relative', left: scrollLeft })
};
  
  const selectReservation = (slot, d) => {
    slot.view = 'horizontal'
    slot.selectedduration = d
    dispatch({
      type: 'UPDATE_RESERVATION',
      payload: slot,
    })
    history.push(appconfig.pathPrefix + '/reservation')
  }

  // console.log('appconfig.resources', appconfig.resources)

  useEffect(() => {
    let resources = getResources(dailyFreeTimes, selectedvenue, appconfig).sort((a, b) => {
      if (a.order) {
        return a.order - b.order
      } else {
        return a.courtname.localeCompare(b.courtname, undefined, { numeric: true, sensitivity: 'base' })
      }
    })
    if (!resources || resources.length === 0) {
      if (appconfig.resources) {
        resources = Object.keys(appconfig.resources).map((r) => {
          return { ...appconfig.resources[r], id: r }
        })
      }
    }

    let daystart = '06:00'
    let dayend = '23:00'
    let intervals = 30
    if (appconfig.times) {
      daystart = appconfig.times.s || daystart
      dayend = appconfig.times.e || dayend
      intervals = appconfig.times.i || intervals
    }
    const times = getTimeStops(servertime ? servertime : daystart, dayend, intervals)
    setTimes(times)
    // const _times = times.map((r) => {
    //   if (r.indexOf(':00') > -1) {
    //     return { type: 'timeheader', key: 'timerow' + r, value: r.split(':')[0].replace(/^0/, '') }
    //   } else {
    //     return { type: 'timeheader', key: 'timerow' + r, value: null }
    //   }
    // })
    // _times.unshift({ type: 'nullcorner' })
    const resourceTimes = getResourceTimes(dailyFreeTimes, selectedvenue, appconfig)

    const trs = []
    console.log('resources', resources, times, resourceTimes)

    for (let resource of resources) {
      const __row = getResourceRow(resource, times, resourceTimes, ownreservations)
      // const __row = times.map((t) => {
      //   const _t = moment.utc(t, 'HH:mm')
      //   if (resourceTimes[resource.id] && resourceTimes[resource.id].length > 0) {
      //     const dateSetter = resourceTimes[resource.id][0].startmoment
      //     _t.year(dateSetter.year()).month(dateSetter.month()).date(dateSetter.date())
      //   }
      //   const isStart = resourceTimes[resource.id].find((r) => t === r.startmoment.format('HH:mm'))
      //   const isVacancy = resourceTimes[resource.id].find((r) => {
      //     if (r.times && r.times.length > 0) {
      //       const duration = r.times[r.times.length - 1].duration
      //       const _s = moment.utc(r.startmoment)
      //       return _t.isBetween(_s, moment.utc(r.startmoment).add(duration, 'minutes'), null, '[)')
      //     } else {
      //       return false
      //     }
      //   })
      //     ? true
      //     : false
      //   return { t, isStart, isVacancy }
      // })

      const __tds = []
      __tds.push(resource)
      // const __rowCells = []
      // __rowCells.push({ type: 'resourceheader', resource })

      let cname = resource.courtname ? resource.courtname : ''
      if (width <= 770 && cname.length > 4) cname = cname.substring(0, 2) + cname.replace(/[^0-9]+/, '')
      __tds.push(
        <th key={resource.id} style={styles.verticalresource}>
          {cname}
        </th>
      )
      __row.forEach((r, i) => {
        const end = __row[i + 1] && __row[i + 1].isVacancy ? false : true
        const spanstart = __row[i - 1] && __row[i - 1].isVacancy ? false : true
        
        if (r.isOwn) {
          console.log('Mika', r)
          const span = 1
          let popupLocationIndicator = 'po' + r.isOwn.id + moment.utc(r.t).format('HHmm')
          __tds.push(
            <OwnCell
              key={popupLocationIndicator + '' + Math.random().toString().replace('.', '')}
              cell={r.isOwn}
              onClick={handleClick}
              span={span}
              time={r.t}
              start={true}
              end={false}
            />
          )
        } else if (r.isOwnDuration) {
          __tds.push(
            <td
              key={'cell' + i + Math.random().toString().replace('.', '')}
              style={ownCellStyle({ hover: false, start: false, end: true })}
            />
          )
        } else if (r.isStart) {
            const span = 1
            let popupLocationIndicator = 'po' + r.isStart.id + moment.utc(r.isStart.start).format('HHmm')
            // __rowCells.push({
            //   type: 'cell',
            //   key: popupLocationIndicator + '' + Math.random().toString().replace('.', ''),
            //   reservation: r.isStart,
            //   span,
            //   start: spanstart,
            //   end,
            // })
            __tds.push(
              <SelectorCell
                key={popupLocationIndicator + '' + Math.random().toString().replace('.', '')}
                cell={r.isStart}
                onClick={handleClick}
                span={span}
                time={moment.utc(r.isStart.start).format('H:mm')}
                start={spanstart}
                end={end}
              />
            )
        } else if (r.isVacancy) {
          __tds.push(
            <td
              key={'cell' + i + Math.random().toString().replace('.', '')}
              style={selectorCellStyle({ hover: false, start: false, end })}
            />
          )
        } else {
          __tds.push(<td key={'cell' + i + Math.random().toString().replace('.', '')} style={styles.cell} />)
        }
      })
      // console.log('updating render', scrollPosition)
      // trs.push([<th key={resource.id} style={styles.verticalresource} className='resourcename' colSpan={__tds.length}><div style={{ position: 'relative', height: 30 }}><div style={scrollPosition}>{resource.courtname}</div></div></th>])
      trs.push(__tds)
    }
    setTrs(trs)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyFreeTimes, selectedvenue, appconfig, servertime, width, ownreservations])

  let isEmptyCategory = true
  for (let ___tr of trs) {
    for (let ___td of ___tr) {
      if (___td.key && ___td.key.startsWith('po')) {
        isEmptyCategory = false
        break
      }
    }
  }

  return (
    <React.Fragment>
      <div id="scrol" style={{ overflowX: 'auto' }} onScroll={handleScroll}>
        <table style={styles.table}>
          <thead>
            <tr>
              <th id='Popover1'></th>
              {times &&
                times.length > 0 &&
                times.map((r) => {
                  if (r.indexOf(':00') > -1) {
                    return (
                      <th key={'timerow' + r} style={styles.thtime}>
                        {r.split(':')[0].replace(/^0/, '')}
                      </th>
                    )
                  } else {
                    return <th key={'timerow' + r} style={styles.thtime}></th>
                  }
                })}
            </tr>
          </thead>
          <tbody>
            {trs &&
              trs.length > 0 && !isEmptyCategory ?
              trs.map((r, i) => {
                const _r = r.toSpliced(0, isSm ? 2 : 1)
                return (
                  <React.Fragment  key={'timerows' + i.toString()}>
                    {isSm ? <tr style={styles.tr}>
                      <th key={'resourcetimerows' + i.toString()} style={styles.verticalresource} className='resourcename' colSpan={_r.length}><div style={{ position: 'relative', height: 30 }}><div style={scrollPosition}>{r[0].courtname}</div></div></th>
                    </tr> : null}
                    <tr style={styles.tr}>
                      {_r}
                    </tr>
                  </React.Fragment>
                )
              }): <tr><td colSpan={times.length}><div style={{ textAlign: 'center', marginTop: 20 }}>{t('no_vacancy')}</div></td></tr>}
          </tbody>
        </table>
        <DurationPopover
          isOpen={durationDialogOpen}
          toggle={toggle}
          slot={selectedSlot}
          select={selectReservation}
          view={'horizontal'}
          />
      </div>
    </React.Fragment>
  )
}
export default withAppconfig(enhance(HorizontalView))
