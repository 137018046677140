import React, { useState } from 'react'
import { connect } from 'react-redux'
import withAppconfig from '../../AppContext.js'
import {
  Container,
  Typography,
  CircularProgress,
  Grid,
  Link,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import { firestoreConnect, useFirebase } from 'react-redux-firebase'
import { Warning } from '@material-ui/icons'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/sv'
import 'moment/locale/fi'
import 'moment/locale/et'
// import { t } from 'i18next'
import { useHistory } from 'react-router-dom'
// import { hasResource, getStatus } from './ReservationUtil'
// import ReservationSummary from './ReservationSummary.js'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MatchSummary from './MatchSummary.js'
import ShareDialog from '../ShareDialog.js'

const mapStateToProps = (state, props) => {
  const {
    firestore,
    firebase: { profile, auth },
  } = state

  if (!auth.uid) return { reservations: undefined }
  const { appconfig } = props
  if (firestore.data['ownMatches']) {
    let accesscode = profile.accesscode
      ? profile.accesscode[appconfig.accountkey]
      : null
    if (accesscode && appconfig.accesscodeextra)
      accesscode += appconfig.accesscodeextra

    let _m = Object.keys(firestore.data['ownMatches'])
      .map((key) => {
        return {
          ...firestore.data['ownMatches'][key],
          id: key,
          selectkey: key,
        }
      })
      // .filter(
      //   (r) =>
      //     hasResource(r) &&
      //     r.locationid === appconfig.accountkey &&
      //     r.state !== 'user_cancelled'
      // )
      // .map((r) => {
      //   if (r.isrecurring) {
      //     return r.recurrencyDates
      //       .map((rec) => {
      //         if (rec.state === 'moved') return null
      //         let _r = JSON.parse(JSON.stringify(r))
      //         let _sKey = _r.selectkey + '-'
      //         if (typeof rec === 'object') {
      //           _r.occurency = {
      //             date: rec.date,
      //             state: rec.state,
      //             isstored: rec.isstored,
      //             refundinvoicing: rec.refundinvoicing,
      //             salestatus: rec.salestatus,
      //           }
      //           _sKey += rec.date
      //         } else {
      //           _r.occurency = { date: rec }
      //           _sKey += rec
      //         }
      //         _r.selectkey = _sKey // _r.selectkey + '-' + rec
      //         const timeOfDayHelper = moment.utc(_r.resourcegroups[0].end)
      //         const ordering = moment.utc(_r.occurency.date)
      //         ordering
      //           .hour(timeOfDayHelper.hour())
      //           .minute(timeOfDayHelper.minute())
      //         if (moment().utcOffset() > 0) {
      //           ordering.subtract(moment().utcOffset(), 'minute')
      //         } else {
      //           ordering.add(moment().utcOffset(), 'minute')
      //         }
      //         _r.ordering = ordering.unix()
      //         return _r
      //       })
      //       .filter((x) => x)
      //   } else {
      //     const ordering = moment.utc(r.resourcegroups[0].end)
      //     if (moment().utcOffset() > 0) {
      //       ordering.subtract(moment().utcOffset(), 'minute')
      //     } else {
      //       ordering.add(moment().utcOffset(), 'minute')
      //     }
      //     r.ordering = ordering.unix()
      //     r.occurency = {
      //       date: moment.utc(r.resourcegroups[0].start).format('YYYY-MM-DD'),
      //     }
      //     return r
      //   }
      // })
      .flat(3)
    // .filter((r) => {
    //   if (appconfig.nextseasonhide) {
    //     if (moment(r.occurency.date).isAfter(appconfig.nextseasonhide)) {
    //       return false
    //     } else {
    //       return true
    //     }
    //   }
    //   return true
    // })
    // .map((r) => {
    //   r.vs = getStatus(r)
    //   return r
    // })

    _m.sort(function (a, b) {
      if (a.ordering < b.ordering) {
        return -1
      } else if (a.ordering > b.ordering) {
        return 1
      } else {
        return 0
      }
    })

    // let _onsale = _m.filter((x) => x.vs.isOnSale && !x.vs.isCancelled)
    // let _upcoming = _m.filter((x) => x.vs.isUpcoming && !x.vs.isCancelled)

    return {
      matches: _m, //: _upcoming.slice(0, 2),
      matcheslength: _m.length, //_upcoming.length,
      accesscode,
    }
  } else {
    return { matches: undefined, matcheslength: 0 }
  }
}

// const getTimestampFromMoment = (dateMoment) => firestore.Timestamp.fromDate(dateMoment.toDate())

const withMatches = compose(
  connect((state) => ({
    auth: state.firebase.auth,
    gtime: state.globaltime.gtime,
  })),
  firestoreConnect((props) => {
    const startStamp = props.firestore.Timestamp.fromDate(props.gtime.toDate())
    return [
      {
        collection: 'users',
        doc: `${props.auth.uid}`,
        subcollections: [{ collection: 'matches' }],
        where: [['startAsTimestamp', '>=', startStamp], ['userstate', '==', 'participating']],
        storeAs: 'ownMatches',
      },
    ]
  }),
  connect(mapStateToProps)
)

function NextMatches({
  matches,
  accesscode,
  appconfig,
  matcheslength,
  dispatch,
  auth,
}) {
  const firebase = useFirebase()
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(true)
  const [matchesList, setMatchesList] = React.useState([])
  const history = useHistory()
  const isSm = useMediaQuery('(max-width:600px)')
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [selectedMatch, setSelectedMatch] = useState(null)
  const [socialOpen, setSocialOpen] = useState(false)
  const [sharelink, setShareLink] = useState('')

  React.useEffect(() => {
    if (matches) {
      setMatchesList(matches)
      setLoading(false)
    }
  }, [matches])

  const splitPayment = (reservation) => {
    dispatch({
      type: 'UPDATE_SPLITTABLE_RESERVATION',
      payload: reservation,
    })
    history.push(appconfig.pathPrefix + '/splitpayment')
  }

  const cancelModal = () => {
    setSelectedMatch(null)
    setConfirmationOpen(false)
  }

  const confirmCancel = (match) => {
    const isCreator = match.participants.find(
      (p) => p.id === auth.uid && p.creator
    )
      ? true
      : false
    match.isCreator = isCreator
    setSelectedMatch(match)
    setConfirmationOpen(true)
  }

  const cancelMatchParticipation = async () => {
    setConfirmationOpen(false)
    setLoading(true)
    const matchObject = {
      id: selectedMatch.id,
      customerid: appconfig.accountkey,
    }
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'matches-cancel' : 'matches-cancel'
    const cancel = firebase.functions().httpsCallable(fn)
    try {
      const response = await cancel(matchObject)
      setLoading(false)
      if (response.data.error) {
        alert(response.data.message)
      }
    } catch (error) {
      setLoading(false)
      alert(error.message)
    }
  }

  const closeSocial = () => {
    setSocialOpen(false)
  }

  const shareClick = (match) => {
    const link =
      'https://' +
      (appconfig.istest
        ? 'utest.cintoia.com' + appconfig.pathPrefix
        : appconfig.hosts[0]) +
      '/matches/' +
      match.id
    setShareLink(link)
    setSocialOpen(true)
  }

  if (!matches || matches.length === 0) return null
  return (
    <Container>
      <Typography variant='h6'>{t('next_matches')}</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={isSm ? 0 : 1}>
          {matchesList.map((r, i) => (
            <Grid
              item
              xs={12}
              //md={matches.length === 1 ? 12 : 6}
              key={r.id + '---' + i}
            >
              <MatchSummary
                key={r.id}
                match={r}
                cancelAction={confirmCancel}
                shareClick={shareClick}
                // accesscode={accesscode}
                // onSplitpayment={splitPayment}
              />
              {/* <ReservationSummary key={r.id} r={r} accesscode={accesscode} onSplitpayment={splitPayment} /> */}
            </Grid>
          ))}
          {/* {reservationslength > 2 && (
            <Grid item xs={12}>
              <Box display='flex' justifyContent='flex-end'>
                <Link
                  component='button'
                  variant='body2'
                  onClick={() =>
                    history.push(appconfig.pathPrefix + '/matches')
                  }
                >
                  {t('view_all')}
                </Link>
              </Box>
            </Grid>
          )} */}
        </Grid>
      )}
      {/* <pre>{JSON.stringify(matches, null, 2)}</pre> */}
      <Dialog
        open={confirmationOpen}
        onClose={() => cancelModal()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
        {selectedMatch && selectedMatch.isCreator ? <Warning htmlColor='orange' fontSize='large' /> : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Typography variant='h6'>
              {selectedMatch && selectedMatch.isCreator
                ? 'Olet luonut tämän ottelun ja jos perut ottelun, se poistetaan kaikilta osallistujilta. Haluatko varmasti perua koko ottelun?'
                : 'Haluatko varmasti perua osallistumisen?'}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancelModal()} color='secondary'>
            {t('back')}
          </Button>
          <Button
            onClick={() => cancelMatchParticipation()}
            color='danger'
            autoFocus
          >
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <ShareDialog open={socialOpen} onClose={closeSocial} link={sharelink} />
    </Container>
  )
}

export default withAppconfig(withMatches(NextMatches))
